import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import PricingOne from '../component/pricing/PricingOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterTwo from '../common/footer/FooterTwo';
import TawkToMessenger from '../components/common/TawkToMessenger';

const DigitalAgency = () => {

    return (
        <>
        <SEO title="메인"/>
        <ColorSwitcher />
        <TawkToMessenger />
        <main className="main-wrapper">
            <HeaderOne />
            <BannerOne />
            <div className="section section-padding-2 bg-color-dark">
                <div className="container">
                <SectionTitle 
                    subtitle="최저가 40만원부터 시작하는 원포인트 솔루션"
                    title="지금 어려워하는 문제, 바로 해결해 드립니다!"
                    description="복잡한 버그 수정, 기능 추가, 문제 해결까지! 전문가의 빠르고 정확한 솔루션으로 당신의 걱정을 해결하세요."
                    textAlignment="heading-light-left"
                        textColor=""
                    />
                    <div className='row'>
                        <ServicePropOne colSize="col-xl-6 col-md-6" serviceStyle="" itemShow="3" />
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div>
            <AboutOne />
            <TestimonialOne />
            <CtaLayoutOne /> 
            <FooterTwo />
        </main>
        </>
    )
}

export default DigitalAgency;

