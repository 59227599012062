import { useEffect } from 'react';

const TawkToMessenger = () => {
    useEffect(() => {
        // Tawk.to 스크립트 생성
        const s1 = document.createElement("script");
        const s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/6766bd84af5bfec1dbdf83df/1ifkl8jvn";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
    
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.onLoad = function () {
            const bubble = document.getElementById('tawk-bubble-container');
            if (bubble) {
                bubble.style.top = '50px';   // 상단으로 이동
                bubble.style.right = '20px'; // 오른쪽 여백 설정
            }
        };
    
        return () => {
            document.head.removeChild(s1);
        };

        // 채팅 위젯 위치 조정을 위한 스타일 추가
     
    }, []);

    return null;
};

export default TawkToMessenger;