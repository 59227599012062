import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "정확한 문제 파악과 원포인트 솔루션",
        para: "약 4시간 이내에 문제를 파악하고, 최적의 해결 방향을 제시합니다. 대표님이 원하는 방식대로 해결 방법을 설계하고 진행하며, AWS, GCP 등 클라우드 운영 중 발생한 장애 대응까지 전문적으로 지원합니다."
    },
    {
        id: 2,
        title: "정직한 비용 청구와 유연한 운영",
        para: "저희는 매달 정해진 비용을 청구하지 않습니다. 작업량과 난이도에 따라 일한 만큼만 정직하게 비용을 청구합니다. 고객의 예산과 요구에 맞춘 유연한 운영 방식을 보장합니다."
    },
    {
        id: 3,
        title: "프로젝트 오너십과 책임감",
        para: "모든 프로젝트는 전담 담당자가 배정되어 고객과의 소통 및 관리를 책임집니다. 각 개발자는 프로젝트를 자신의 명예와 역량이 걸린 중요한 작업으로 여기며, 최고 품질의 결과물을 제공하기 위해 최선을 다합니다."
    },
    {
        id: 4,
        title: "안정성과 확장성을 겸비한 개발",
        para: "캐나다 대기업 및 스타트업에서 스케일업을 경험한 개발자들이 클라우드 기반 아키텍처를 활용하여 안정적이고 확장 가능한 프로그램을 개발합니다. 고객의 비즈니스 성공을 위해 최적의 기술 솔루션을 제공합니다."
    },
    {
        id: 5,
        title: "직접 개발과 품질 보장",
        para: "모든 작업은 내부 전문 개발팀에 의해 직접 수행되며, 하청 없이 관리됩니다. 우리는 고객이 원하는 기능을 기획 의도에 맞게 완벽히 구현하며, 고객의 만족을 최우선으로 두고 고품질 결과물을 제공합니다."
    },
    {
        id: 6,
        title: "프로젝트 보안과 신뢰",
        para: "저희는 고객의 프로젝트를 철저히 보안 관리하며, 외부에 노출되지 않도록 보장합니다. 특히, 저희가 작업한 프로젝트는 절대 웹사이트나 포트폴리오에 노출하지 않습니다. 고객의 데이터를 안전하게 보호하며, 보안이 유지된 환경에서만 작업을 진행합니다."
    }
];


const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
            <SectionTitle 
    subtitle="우리가 제공하는 전문 솔루션"
    title="지금 고민하시는 문제,<br/>저희가 한 번에 <br/>해결합니다!"
    description="현재 한국 개발자 시장은 과잉 공급 상태입니다. 하지만 저희는 단순히 많은 개발자를 보유한 것이 아니라, 가장 효율적이고 논리적인 인재만을 선발하여 문제를 해결합니다. 특히, 저희 CEO는 세계적으로 유명한 토론토대학교 컴퓨터 공학과 출신으로, 전문성을 바탕으로 복잡한 문제를 빠르게 진단하고 최적의 해결책을 제공합니다. 문제 파악부터 해결, 그리고 유지보수까지 한 번에 끝내 드리는 원스톱 서비스를 경험해보세요."
    textAlignment="heading-left heading-light-left mb--100"
    textColor=""
/>


                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;