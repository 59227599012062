import React from 'react';
import FormOne from '../contact/FormOne';

const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                        <div className="section-heading heading-left mb-0">
                                <span className="subtitle">왜 우리를 선택해야 할까요?</span>
                                <h2 className="title mb--40">버그 수정, 개발, 운영<br />단 한 번에 해결해 드립니다.</h2>
                                <p>최저가 40만원부터 시작하는 원포인트 솔루션! 어려운 문제를 빠르고 완벽하게 해결합니다. 고객의 아이디어를 현실로 만들어드리겠습니다.</p>
                                <p>Node.js, React.js, Flutter 등 최신 기술 스택과 데이터베이스 기술로 최적의 솔루션을 제공합니다. 기술 추천부터 개발, 유지보수까지 완벽히 지원합니다.</p>
                                <p>여러분의 성공이 곧 우리의 목표입니다. 빠르고 확실한 결과로 신뢰를 증명하겠습니다.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">지금 무료로 견적을 <br/>받아보세요</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="버블" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="라인" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="라인" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;