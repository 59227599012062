import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import TestimonialOne from '../component/testimonial/TestimonialOne';
import { slugify } from '../utils';
import FooterTwo from '../common/footer/FooterTwo';
const allData = ServiceData;
const services = [
    {
        id: 1,
        title: "모바일 / 웹 개발",
        subtitle: "PC / Mobile Web 사이트 개발",
        description: "최적의 기획과 설계를 통해 국내외 다양한 프로젝트에서 검증된 경험을 바탕으로 고객의 아이디어를 구체화합니다."
    },
    {
        id: 2,
        title: "서비스 원포인트 솔루션",
        subtitle: "버그 수정 / 문제점 파악 / 기능 추가 및 변경",
        description: "Node.js, React.js 등 최신 스택으로 구축된 프로젝트의 문제를 정확히 파악하고, 필요한 수정이나 기능 추가를 제공합니다. 서비스 비용은 작업량과 난이도에 비례해 책정되며, 항상 정직하고 합리적인 가격을 보장합니다."
    },
    {
        id: 3,
        title: "앱 컨설팅",
        subtitle: "전문적인 앱 컨설팅 서비스",
        description: "사용자 경험(UX)과 기능적 요구사항을 충족시키는 동시에, 기술적 효율성을 극대화하는 앱 설계와 개발 전략을 제안합니다. 비즈니스 목표 달성을 위한 체계적이고 전문적인 컨설팅을 제공합니다."
    },
    {
        id: 4,
        title: "프로젝트 보안 철저",
        subtitle: "철저한 보안 관리와 서포트",
        description: "저희는 고객의 프로젝트를 포트폴리오로 사용하지 않고, 철저한 보안 관리로 외부 노출 없이 뒷받침하는 데 초점을 맞춥니다. 고객의 성공이 곧 저희의 실력을 증명하는 척도라고 믿으며, 보안을 최우선으로 생각합니다."
    },
];


const ServiceOne = () => {

    const designData = allData.filter(data => slugify(data.cate ? data.cate : "") === "design");
    const developmentData = allData.filter(data => slugify(data.cate ? data.cate : "") === "development");
    const marketingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "online-marketing");
    const businessData = allData.filter(data => slugify(data.cate ? data.cate : "") === "business");
    const technologyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "technology");
    const strategyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "content-strategy");



    return (
        <>
        <SEO title="Service One" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
    title="최저가 40만원부터 시작하는 디지털 컨설팅 솔루션"
    paragraph="복잡한 버그 수정, 문제 해결, 기능 추가를 단 한 번에! 당신의 비즈니스 아이디어를 성공으로 이끄는 정직한 원포인트 솔루션을 제공합니다."
    styleClass=""
    mainThumb="/images/banner/banner-thumb-4.png"
/>
                <div className="section section-padding">
                    <div className="container">
                        <h2 className="service-title">hAi 제공 서비스</h2>
                        <div className="service-list">
                            {services.map(service => (
                                <div key={service.id} className="service-item">
                                    <div className="service-header">
                                        <h3>{service.title}</h3>
                                        <p>{service.subtitle}</p>
                                    </div>
                                    <div className="service-body">
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
        </div>
     
            <TestimonialOne />
            <CtaLayoutOne />
            <FooterTwo />
        </main>
        </>
    )
}

export default ServiceOne;