import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-us-3.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                        <h2 className="title">단 한 번에 해결하는 <br/> 원포인트  솔루션</h2>
<p>저희 개발팀은 대기업부터 초기 스타트업까지 다양한 규모의 프로젝트를 성공적으로 수행한 전문가들로 구성되어 있습니다. 
특히 저희 CEO는 초기 의료 AI 스타트업의 10명 규모 팀에서 시작해, 100명 내외의 회사로 성장시키며 개발 그룹장으로서 복잡한 문제를 해결하고 팀을 이끄는 데 핵심적인 역할을 수행했습니다.</p>
<p>저희 팀은 문제를 신속히 파악하고, 필요한 지원과 맞춤형 해결책을 제공하는 능력에 있어 누구보다 뛰어납니다. 복잡한 장애나 기능 요구사항도 단 한 번의 솔루션으로 해결하며, 퀄리티와 속도를 모두 보장합니다. 지금 바로 최고의 결과를 경험해보세요.</p>
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count" style={{color:'green'}}>
                                        {isVisible ? <CountUp end="20" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol" style={{color:'green'}}>+ </span>
                            </h2>
                            <span className="counter-title" >성공한 프로젝트</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count" style={{color:"#FFCD00"}}>
                                        {isVisible ? <CountUp end="4.5" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol" style={{color:"#FFCD00"}}> &nbsp; / 5</span>
                            </h2>
                            <span className="counter-title">
                                평균 별점
                                
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;